import type { PaginationParams, R_Pagination } from '~/types/global'
import { requestWithAdmin } from './AxiosInstance/admin'

// Dict

export interface DictMapItem {
  label: string
  value: string
}

export type DictMapDirectory = Record<string, DictMapItem[]>

export interface DictParams extends Partial<DictRecord>, PaginationParams {}

export interface DictRecord {
  id: string
  remarks: string
  description: string
  dictType: string
  systemFlag: string
  createBy: string
  createTime: string
  updateBy: string
  updateTime: string
}

export interface DictQuery {
  dictType: string
}

export function initDictQueryForm(): DictQuery {
  return {
    dictType: '',
  }
}

export function initDictForm(): DictRecord {
  return {
    id: '',
    remarks: '',
    description: '',
    dictType: '',
    systemFlag: '0',
    createBy: '',
    createTime: '',
    updateBy: '',
    updateTime: '',
  }
}

export function queryDictPage(params: DictParams): R_Pagination<DictRecord> {
  return requestWithAdmin.request({
    url: '/admin/dict/page',
    method: 'get',
    params,
  })
}

export function queryDictList() {
  return requestWithAdmin.request<Array<DictRecord>>({
    url: '/admin/dict/list',
    method: 'get',
  })
}

export function queryDict(id: string) {
  return requestWithAdmin.get(`/admin/dict/${id}`)
}

export function deleteDict(id: string) {
  return requestWithAdmin.delete(`/admin/dict/${id}`)
}

export function postDict(data: DictRecord) {
  return requestWithAdmin.request({
    url: '/admin/dict',
    method: 'post',
    data,
  })
}

export function putDict(data: DictRecord) {
  return requestWithAdmin.request({
    url: '/admin/dict',
    method: 'put',
    data,
  })
}

export interface DictItemRecord {
  id: string
  dictId: string
  remarks: string
  description: string
  dictType: string
  systemFlag: string
  createBy: string
  createTime: string
  updateBy: string
  updateTime: string
  sortOrder: number
  value: string
  label: string
}

export interface DictItemParams extends Partial<DictItemRecord>, PaginationParams {}

export function initDictItemForm(): DictItemRecord {
  return {
    id: '',
    dictId: '',
    remarks: '',
    description: '',
    dictType: '',
    systemFlag: '0',
    createBy: '',
    createTime: '',
    updateBy: '',
    updateTime: '',
    sortOrder: 0,
    value: '',
    label: '',
  }
}

export interface DictItemQuery {
  dictType: string
}

export function initDictItemQueryForm(): DictItemQuery {
  return {
    dictType: '',
  }
}
export function queryDictItemPage(params: DictItemParams): R_Pagination<DictItemRecord> {
  return requestWithAdmin.request({
    url: '/admin/dict/item/page',
    method: 'get',
    params,
  })
}

export function deleteDictItem(id: string) {
  return requestWithAdmin.delete(`/admin/dict/item/${id}`)
}

export function postDictItem(data: DictItemRecord) {
  return requestWithAdmin.request({
    url: '/admin/dict/item',
    method: 'post',
    data,
  })
}

export function putDictItem(data: DictItemRecord) {
  return requestWithAdmin.request({
    url: '/admin/dict/item',
    method: 'put',
    data,
  })
}

export function queryDictMap() {
  return requestWithAdmin.get('/admin/dict/map')
}
