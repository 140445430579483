import NProgress from 'nprogress'

import { createRouter, createWebHistory } from 'vue-router'

import createRouteGuard from './guard'

import { appRoutes } from './routes'
// progress bar
import 'nprogress/nprogress.css'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'LandingPage',
      component: () => import('~/pages/landing-page/index.vue'),
      meta: {
        requiresAuth: false,
        locale: '首页',
      },
    },
    {
      path: '/login',
      name: loginRouteName,
      component: () => import('~/pages/login/index.vue'),
      meta: {
        requiresAuth: false,
        locale: '登录账号',
      },
    },
    {
      path: '/register',
      name: registerRouteName,
      component: () => import('~/pages/register/index.vue'),
      meta: {
        requiresAuth: false,
        locale: '注册航运账号',
      },
    },
    {
      path: '/forget',
      name: forgetRouteName,
      component: () => import('~/pages/forget/index.vue'),
      meta: {
        requiresAuth: false,
        locale: '忘记密码',
      },
    },
    ...appRoutes,
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: () => import('~/pages/dashboard/index.vue'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/pay',
      name: 'Pay',
      component: () => import('~/pages/pay/index.vue'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'notFound',
      component: () => import('~/pages/not-found/index.vue'),
      meta: {
        requiresAuth: false,
      },
    },
  ],
  scrollBehavior() {
    return { top: 0 }
  },
})

createRouteGuard(router)

export default router
