import type { AppRouteRecordRaw } from '../types'
import { ROLE_NAME } from '~/composables/constant'
import { DEFAULT_LAYOUT } from '~/router/constants'

const PRICE: AppRouteRecordRaw = {
  path: '/eval_index',
  name: 'EvalIndex',
  component: DEFAULT_LAYOUT,
  redirect: '/eval_index/ship',
  meta: {
    locale: '综合评价指数',
    requiresAuth: true,
    icon: 'icon-bar-chart',
    order: 8,
  },
  children: [
    {
      path: 'ship',
      name: 'ShipEvalIndex',
      component: () => import('~/views/eval_index/ship/index.vue'),
      meta: {
        locale: '船舶评价指数',
        requiresAuth: true,
        roles: [
          ROLE_NAME.超级管理员, // 0
          ROLE_NAME.航运管理员, // 1
          ROLE_NAME.航运账号, // 1
          ROLE_NAME.海事管理员, // 3
          ROLE_NAME.海事账号, // 3
        ],
      },
    },
  ],
}

export default PRICE
