import type { InternalAxiosRequestConfig } from 'axios'
import { getToken } from '~/utils/auth'

export function requestInterceptor(req: InternalAxiosRequestConfig) {
  // let each request carry token
  // this example using the JWT token
  // Authorization is a custom headers key
  // please modify it according to the actual situation
  const token = getToken()
  if (token) {
    if (req.headers)
      req.headers.set('Authorization', `Bearer ${token}`)
  }

  return req
}
