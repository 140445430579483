import { keyBy, mapValues } from 'lodash-es'
import { type DictMapDirectory, queryDictMap } from '~/api/dict'
import type { CompanyDict } from '~/api/vqc/company_info'
import type { MessageRecord } from '~/api/vqc/message'
import type { ShipDict } from '~/api/vqc/shipinfo'
import defaultSettings from '~/config/settings.json'
import { emitter } from '~/utils/event'
import { ROLE_NAME } from './constant'

function ls<T>(id: string, defaultValue: T): Ref<T> {
  return useStorage(`vqc-${id}`, defaultValue)
}

// 初始化就进行字典获取---start
export const storeDictMap = ls<DictMapDirectory>('dict-map', {})
async function queryDict() {
  const { data } = await queryDictMap()
  storeDictMap.value = data
}
queryDict()
// ---end

export const storeUserId = ls('user-id', '')
export const storeUserRoleCode = ls<ROLE_NAME | null>('user-role-code', null)
export const storeUserCompanyId = ls('user-company-id', '')
export const storeUserCompanyName = ls('user-company-name', '')
export const storeUserPremiumCompany = ls<boolean>('user-premium-company', false)

export const storeCompanyList = ls<CompanyDict[]>('company', [])
export const storeShipList = ls<ShipDict[]>('ship', [])
export const storeUpdateDialogShow = ls<boolean>('update-dialog-show', true)
export const storeSettings = ls<any>('settings', defaultSettings)
// export const storeTrialVip = ls<boolean>('trial-vip', true)

export const globalMtPremiumCompanyDialogShow = ref(false)
export const globalHyPremiumCompanyPriceInfoDialogShow = ref(false)
export const globalHyPremiumCompanyPriceInfoCurrentIdx = ref(0)
export const globalHyPremiumCompanySelectPayDialogShow = ref(false)
export const globalMessageList = ref<MessageRecord[]>([])

export const globalMessageUnreadCount = computed(() => {
  return globalMessageList.value.filter(m => !m.isRead).length
})

// export function toggleTrialVip() {
//   storeTrialVip.value = !storeTrialVip.value
// }

export const storeComputeCompanyMap = computed(() => {
  return mapValues(keyBy(storeCompanyList.value, 'id'), 'name')
})

export const storeComputeShipMap = computed(() => {
  return mapValues(keyBy(storeShipList.value, 'id'), 'shipNameCh')
})
/**
 * 超级管理员
 */
export const storeComputeIsSuper = computed(() => {
  return storeUserRoleCode.value?.startsWith(ROLE_NAME.超级管理员)
})
/**
 * 海事
 */
export const storeComputeIsHs = computed(() => {
  return storeUserRoleCode.value?.startsWith('MARITIME')
})
/**
 * 码头
 */
export const storeComputeIsMt = computed(() => {
  return storeUserRoleCode.value?.startsWith('DOCK')
})
/**
 * 航运
 */
export const storeComputeIsHy = computed(() => {
  return storeUserRoleCode.value?.startsWith('SHIPPING')
})
/**
 * 专家
 */
export const storeComputeIsZj = computed(() => {
  return storeUserRoleCode.value?.startsWith('EXPERT')
})
/**
 * 财务
 */
export const storeComputeIsCw = computed(() => {
  return storeUserRoleCode.value?.startsWith('FIN')
})

// export const storeComputeTrialVip = computed(() => {
//   if (storeComputeIsHy.value)
//     return storeTrialVip.value

//   else
//     return true
// })

export const storeComputeMtBlur = computed(() => {
  if (storeComputeIsMt.value) {
    if (storeUserPremiumCompany.value === false)
      return true
  }
  return false
})

export const globalComputedIsMtVip = computed(() => {
  if (storeComputeIsMt.value && storeUserPremiumCompany.value)
    return true
  return false
})

export const globalComputedIsHyBlur = computed(() => {
  if (storeComputeIsHy.value && !storeUserPremiumCompany.value)
    return true
  return false
})

export const globalComputedIsHyVip = computed(() => {
  if (storeComputeIsHy.value && storeUserPremiumCompany.value)
    return true
  return false
})

export function globalFunctionPriceInfoDialogOpen(index?: number) {
  globalHyPremiumCompanyPriceInfoDialogShow.value = true
  globalHyPremiumCompanyPriceInfoCurrentIdx.value = index ?? globalHyPremiumCompanyPriceInfoCurrentIdx.value
}

export function globalFunctionHyDialogOpen() {
  globalHyPremiumCompanySelectPayDialogShow.value = true
}

export function globalFunctionHyDialogOpenSelectId(id: string) {
  globalFunctionHyDialogOpen()
  emitter.emit('pay-hy-select-ship-id', id)
}
