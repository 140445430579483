import type { UserState } from '~/store/modules/user/types'

const TOKEN_KEY = 'token'

function isLogin() {
  return !!localStorage.getItem(TOKEN_KEY)
}

function getToken() {
  return localStorage.getItem(TOKEN_KEY)
}

function setToken(token: string) {
  localStorage.setItem(TOKEN_KEY, token)
}

function clearToken() {
  localStorage.removeItem(TOKEN_KEY)
}
function setUserInfo(partial: Partial<UserState>) {
  storeUserRoleCode.value = partial.roleCode!
  storeUserCompanyId.value = partial.sysUser?.companyId ?? ''
  storeUserCompanyName.value = partial.sysUser?.companyName ?? ''
  storeUserId.value = partial.sysUser?.userId ?? ''
  storeUserPremiumCompany.value = partial.premiumCompany ?? false
}
function clearAllUserInfo() {
  clearToken()
  storeUserRoleCode.value = ROLE_NAME.超级管理员
  storeUserCompanyId.value = ''
  storeUserCompanyName.value = ''
  storeUserId.value = ''
  storeUserPremiumCompany.value = false
}

export { clearAllUserInfo, clearToken, getToken, isLogin, setToken, setUserInfo }
