import type { PaginationParams, R, R_Pagination } from '~/types/global'
import { requestWithVqc } from '../AxiosInstance/vqc'

// Message
export interface MessageParams extends Partial<MessageRecord>, PaginationParams {

  isRead?: boolean
}
export interface MessageRecord {
  id: string
  isRead: boolean
  content: string
  title: string
  type: string
  sentTime: string
  redirectPath: string
  receiver: string
  receiverId: string
  sender: string
}

export interface MessageQuery {
  companyId: string
  isRead: boolean
}

export function initMessageQueryForm(): MessageQuery {
  return {
    companyId: '',
    isRead: false,
  }
}

export function initMessageForm(): MessageRecord {
  return {
    id: '',
    content: '',
    isRead: true,
    receiver: '',
    receiverId: '',
    redirectPath: '',
    sender: '',
    sentTime: '',
    title: '',
    type: '',
  }
}
export function queryMessagePage(params: MessageParams): R_Pagination<MessageRecord> {
  return requestWithVqc.request({
    url: '/message/page',
    method: 'get',
    params,
  })
}

export function queryMessageList() {
  return requestWithVqc.request<MessageRecord[]>({
    url: '/message/list',
    method: 'get',
  })
}

export function postMessageRead(id: string) {
  return requestWithVqc.request<R<boolean>>({
    url: `/message/read/${id}`,
    method: 'post',
  })
}
