import type { PaginationParams, R_Pagination } from '~/types/global'
import { requestWithVqc } from '../AxiosInstance/vqc'

// Company

export interface CompanyParams extends Partial<CompanyRecord>, PaginationParams {}

export interface CompanyRecord {
  area: string
  companyAbbreviation: string
  companyName: string
  companyType: string
  createTime: string
  id: string
  linkman: string
  phone: string
  pos: number[]
  posStr: string
  servicesScope: string
  status: string
  updateTime: string
  premiumCompany: boolean
}

export interface CompanyDict {
  id: string
  name: string
  type: string
}

export function queryCompanyList(params: Partial<CompanyRecord>) {
  return requestWithVqc.request<Array<CompanyRecord>>({
    url: '/companyinfo/dict/list',
    method: 'get',
    params,
  })
}

export function queryPublicCompanyList(params: Partial<CompanyRecord>) {
  return requestWithVqc.request<Array<CompanyRecord>>({
    url: '/companyinfo/shipping/enterprises/open/list',
    method: 'get',
    params,
  })
}

export interface CompanyQuery {
  companyName: string
  status: string
  linkman: string
}

export function initCompanyQueryForm(): CompanyQuery {
  return {
    companyName: '',
    status: '',
    linkman: '',
  }
}

export function initCompanyForm(): CompanyRecord {
  return {
    area: '',
    companyAbbreviation: '',
    companyName: '',
    companyType: '',
    createTime: '',
    id: '',
    linkman: '',
    phone: '',
    pos: [],
    posStr: '',
    servicesScope: '',
    status: '',
    updateTime: '',
    premiumCompany: false,

  }
}

export function queryCompanyPage(params: CompanyParams): R_Pagination<CompanyRecord> {
  return requestWithVqc.request({
    url: '/companyinfo/page',
    method: 'get',
    params,
  })
}

export function deleteCompany(id: string) {
  return requestWithVqc.delete(`/companyinfo/${id}`)
}

export function postCompany(data: CompanyRecord) {
  return requestWithVqc.request({
    url: '/companyinfo',
    method: 'post',
    data,
  })
}

export function postPublicCompany(data: CompanyRecord) {
  return requestWithVqc.request({
    url: '/companyinfo/shipping/enterprises/create/open',
    method: 'post',
    data,
  })
}

export function putCompany(data: CompanyRecord) {
  return requestWithVqc.request({
    url: '/companyinfo',
    method: 'put',
    data,
  })
}
