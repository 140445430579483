<script lang="ts" setup>
import type { PropType } from 'vue'

const props = defineProps({
  type: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  defaultValue: {
    type: [Boolean, Number] as PropType<any>,
    required: true,
  },
})
const emit = defineEmits(['inputChange'])
function handleChange(value: unknown) {
  emit('inputChange', {
    value,
    key: props.name,
  })
}
</script>

<template>
  <AInputNumber
    v-if="type === 'number'"
    class="w-20"
    size="small"
    :default-value="defaultValue"
    @change="handleChange"
  />
  <ASwitch
    v-else
    :default-checked="defaultValue"
    size="small"
    @change="handleChange"
  />
</template>
