import type { AxiosResponse } from 'axios'
import { Message } from '@arco-design/web-vue'
import { clearAllUserInfo } from '~/utils/auth'

export interface HttpResponse<T = unknown> {
  status: number
  msg: string
  code: number
  data: T
}

export async function responseInterceptor(response: AxiosResponse<any, any>) {
  const data = response.data
  const status = response.status
  const config = response.config
  const headers = response.headers
  const USER_INFO_URL = '/admin/user/info'
  const USER_LOGIN_URL = '/auth/oauth/token'
  const NORMAL_CONTENT_TYPE = 'application/json; charset=UTF-8'
  if (status === 200) {
    if (config.url === USER_LOGIN_URL) {
      return data
    }
    // eslint-disable-next-line ts/ban-ts-comment
    // @ts-expect-error
    else if (headers.get('content-type') === NORMAL_CONTENT_TYPE) {
      if (data.code === 1) {
        if (config.url === USER_INFO_URL) {
        // 用户登出操作
          console.warn('用户登出操作')
          console.warn(data.msg)
          Message.error(data.msg)
          clearAllUserInfo()
        }
        else {
          Message.error(data.msg)
          throw new Error(data.msg)
        }
      }
      else {
      // 正常返回请求
        return data
      }
    }

    else {
      return data
    }
  }
  else {
    // 这里是问题爆出，已经处理各种问题响应
    // 503 服务器错误
    // 426 密码错误
    if (status === 426) {
      Message.error({
        content: data.msg,
        duration: 1000,
      })
      throw new Error(data.msg)
    }
    // 424 token失效
    else if (status === 424) {
      clearAllUserInfo()
      location.href = '/login'
      throw new Error(data.msg)
    }
    // 503 服务器未启动
    else if (status === 503) {
      if (response.config.url === USER_INFO_URL) {
        clearAllUserInfo()
      }
      else {
        Message.error({
          content: data.msg,
          duration: 1000,
        })
      }
      throw new Error(data.msg)
    }
    else if (status === 500) {
      if (response.config.url === USER_INFO_URL) {
        clearAllUserInfo()
      }
      else {
        Message.error({
          content: data.msg || '服务未启动',
          duration: 1000,
        })
      }
      throw new Error('服务出现问题,请稍后重试')
    }
    else if (data.data === 'unauthorized') {
      Message.error({
        content: '用户不存在',
        duration: 1000,
      })
      throw new Error(data.msg)
    }
    else {
      Message.error({
        content: data.msg,
        duration: 1000,
      })
      throw new Error(data.msg)
    }
  }
}
