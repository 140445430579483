<script lang="ts" setup>
import VCharts from 'vue-echarts'

withDefaults(defineProps<{
  options?: any
  autoResize?: boolean
  width?: string
  height?: string
}>(), {
  options: {},
  autoResize: true,
  width: '100%',
  height: '100%',
})

const renderChart = ref(false)

nextTick(() => {
  renderChart.value = true
})
</script>

<template>
  <VCharts
    v-if="renderChart"
    :option="options"
    :autoresize="autoResize"
    :style="{ width, height }"
  />
</template>

<style scoped lang="less"></style>
