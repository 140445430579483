import type { AppRouteRecordRaw } from '../types'
import { DEFAULT_LAYOUT } from '~/router/constants'

const RATING_LIST: AppRouteRecordRaw = {
  path: '/rating_list',
  name: 'RatingList',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: '评级赋分',
    requiresAuth: true,
    icon: 'icon-subscribed',
    order: 0,
  },
  children: [
    {
      path: 'company',
      name: 'CompanyRatingList',
      component: () => import('~/views/rating_list/company/index.vue'),
      meta: {
        locale: '企业赋分列表',
        requiresAuth: true,
        roles: [
          ROLE_NAME.超级管理员, // 0
          ROLE_NAME.航运管理员, // 1
          ROLE_NAME.航运账号, // 1
          ROLE_NAME.码头管理员, // 2
          ROLE_NAME.码头账号, // 2
          ROLE_NAME.海事管理员, // 3
          ROLE_NAME.海事账号, // 3
          ROLE_NAME.专家账号, // 4
          ROLE_NAME.货主账号, // 5
        ],
      },
    },

    {
      path: 'ship',
      name: 'ShipRatingList',
      component: () => import('~/views/rating_list/ship/index.vue'),
      meta: {
        locale: '船舶赋分列表',
        requiresAuth: true,
        roles: [
          ROLE_NAME.超级管理员, // 0
          ROLE_NAME.航运管理员, // 1
          ROLE_NAME.航运账号, // 1
          ROLE_NAME.码头管理员, // 2
          ROLE_NAME.码头账号, // 2
          ROLE_NAME.海事管理员, // 3
          ROLE_NAME.海事账号, // 3
          ROLE_NAME.专家账号, // 4
          ROLE_NAME.货主账号, // 5
        ],
      },
    },

    {
      path: 'blacklist_shipping',
      name: 'ShipRatingBlacklistShipping',
      component: () => import('~/views/rating_list/blacklist/shipping.vue'),
      meta: {
        locale: '码头黑名单列表',
        requiresAuth: true,
        hideInMenu: false,
        roles: [
          ROLE_NAME.超级管理员, // 0
          ROLE_NAME.航运管理员,
          ROLE_NAME.航运账号,
        ],
      },
    },
    {
      path: 'blacklist',
      name: 'ShipRatingBlacklist',
      redirect: '/rating_list/blacklist/index',
      meta: {
        locale: '黑名单列表',
        requiresAuth: true,
        hideInMenu: false,
        roles: [
          ROLE_NAME.超级管理员, // 0
          ROLE_NAME.码头管理员, // 1
          ROLE_NAME.码头账号, // 1
          ROLE_NAME.海事管理员, // 3
          ROLE_NAME.海事账号, // 3
        ],
      },
      children: [
        {
          path: 'index',
          name: 'ShipRatingBlacklistIndex',
          component: () => import('~/views/rating_list/blacklist/index.vue'),
          meta: {
            locale: '船舶黑名单',
            requiresAuth: true,
            hideInMenu: true,
            activeMenu: 'ShipRatingBlacklist',
            roles: [
              ROLE_NAME.超级管理员, // 0
              ROLE_NAME.码头管理员, // 1
              ROLE_NAME.码头账号, // 1
              ROLE_NAME.海事管理员, // 3
              ROLE_NAME.海事账号, // 3
            ],
          },
        },
        {
          path: 'mt',
          name: 'ShipRatingBlacklistMt',
          component: () => import('~/views/rating_list/blacklist/mt.vue'),
          meta: {
            locale: '船舶黑名单(企业版)',
            requiresAuth: true,
            hideInMenu: true,
            activeMenu: 'ShipRatingBlacklist',
            roles: [
              ROLE_NAME.超级管理员, // 0
              ROLE_NAME.码头管理员, // 1
              ROLE_NAME.码头账号, // 1
              ROLE_NAME.海事管理员, // 3
              ROLE_NAME.海事账号, // 3
            ],
          },
        },
      ],
    },
    {
      path: 'details',
      name: 'Details',
      component: () => import('~/views/rating_list/details/index.vue'),
      meta: {
        locale: '评分赋分详情',
        requiresAuth: true,
        hideInMenu: true,
        roles: [
          ROLE_NAME.超级管理员, // 0
          ROLE_NAME.航运管理员, // 1
          ROLE_NAME.航运账号, // 1
          ROLE_NAME.码头管理员, // 2
          ROLE_NAME.码头账号, // 2
          ROLE_NAME.海事管理员, // 3
          ROLE_NAME.海事账号, // 3
          ROLE_NAME.专家账号, // 4
          ROLE_NAME.货主账号, // 5
        ],
      },
    },
  ],
}

export default RATING_LIST
