import { queryCompanyList } from '~/api/vqc/company_info'
import { queryMessageList } from '~/api/vqc/message'
import { queryShipList } from '~/api/vqc/shipinfo'

export async function queryCompany() {
  //
  const { data } = await queryCompanyList({})
  storeCompanyList.value = data.map(item => ({
    id: item.id,
    name: item.companyName,
    type: item.companyType,
  }))
}
export async function queryShip() {
  //
  const { data } = await queryShipList()
  storeShipList.value = data
}

// async function queryDict() {
//   const { data } = await queryDictMap()
//   storeDictMap.value = data
// }
export async function queryMessage() {
  const { data } = await queryMessageList()
  globalMessageList.value = data
}

export function queryAll() {
  // queryCategory()
  // queryDict()
  queryCompany()
  queryShip()
  queryMessage()
}
