import type { RouteRecordNormalized } from 'vue-router'
import type { AppState } from './types'
import nProgress from 'nprogress'
import { defineStore } from 'pinia'

const useAppStore = defineStore('app', {
  state: (): AppState => storeSettings.value,

  getters: {
    appNProgressStatus() {
      return nProgress.status
    },
    appCurrentSetting(_state: AppState): AppState {
      return storeSettings.value
    },
    appDevice(_state: AppState) {
      return storeSettings.value.device
    },
    appAsyncMenus(_state: AppState): RouteRecordNormalized[] {
      return storeSettings.value.serverMenu as unknown as RouteRecordNormalized[]
    },
  },

  actions: {
    // Update app settings
    updateSettings(partial: Partial<AppState>) {
    // @ts-expect-error-next-line
      this.$patch(partial)
      storeSettings.value = {
        ...storeSettings.value,
        ...partial,
      }
    },
    toggleDevice(device: string) {
      storeSettings.value.device = device
    },
    toggleMenu(value: boolean) {
      storeSettings.value.hideMenu = value
    },
    clearServerMenu() {
      storeSettings.value.serverMenu = []
    },
  },
})

export default useAppStore
