import { Message } from '@arco-design/web-vue'

export function errorInterceptor(error: any) {
  console.warn(error)
  Message.error({
    content: error.msg || 'Request Error',
    duration: 5 * 1000,
  })

  return Promise.reject(new Error(error.msg || 'Error'))
}
