import type { RouteLocationNormalized, RouteRecordRaw } from 'vue-router'

export function usePermissions() {
  return {
    accessRouter(route: RouteLocationNormalized | RouteRecordRaw) {
      return (
        !route.meta?.requiresAuth
        || !route.meta?.roles
        || route.meta?.roles?.includes('*')
        || route.meta?.roles?.includes(storeUserRoleCode.value)
      )
    },
    findFirstPermissionRoute(_routers: any, role = 'admin') {
      const cloneRouters = [..._routers]
      while (cloneRouters.length) {
        const firstElement = cloneRouters.shift()
        if (
          firstElement?.meta?.roles?.find((el: string[]) => {
            return el.includes('*') || el.includes(role)
          })
        ) {
          return { name: firstElement.name }
        }
        if (firstElement?.children)
          cloneRouters.push(...firstElement.children)
      }
      return null
    },
    // You can add any rules you want
  }
}
