import ArcoVue from '@arco-design/web-vue'
import ArcoVueIcon from '@arco-design/web-vue/es/icon'
import { createApp } from 'vue'

import globalComponents from '~/components'
import App from './App.vue'
import router from './router'
import store from './store'

import '@unocss/reset/tailwind.css'
import '@arco-design/web-vue/dist/arco.css'
import '~/assets/style/global.css'
import 'uno.css'

const app = createApp(App)
if (import.meta.env.PROD && import.meta.env.VITE_BRANCH_NAME === 'main') {
  // eslint-disable-next-line no-console
  console.log(`版本号: ${import.meta.env.RELEASE}\n编译时间: ${import.meta.env.BUILD_TIME}\nNodejs 版本: ${import.meta.env.BUILD_NODE}`)
  // app.use(matomo, {
  //   host: 'https://matomo.vip.cpolar.top',
  //   siteId: 2,
  //   router,
  // })
}

app.use(ArcoVue, {})
app.use(ArcoVueIcon)

app.use(router)
app.use(store)
app.use(globalComponents)

app.mount('#app')
