import type { TableSortable } from '@arco-design/web-vue'

export const BASE_URL = '/api'

export const loginRouteName = 'Login'
export const registerRouteName = 'Register'
export const forgetRouteName = 'Forget'
export const defaultRouteName = 'AccountCenter'
export const landingPageRouteName = 'LandingPage'

export const WEBSITE_TITLE = '到港船舶质量控制公共服务平台'

export const VESSEL_CONDITION_WEIGHT = 20
export const VESSEL_CONDITION_TEXT = '船舶自身状况因素'
export const MARITIME_REGULATION_WEIGHT = 30
export const MARITIME_REGULATION_TEXT = '海事监管结果因素'
export const INDUSTRY_SELF_REGULATION_WEIGHT = 20
export const INDUSTRY_SELF_REGULATION_TEXT = '行业自律检查结果因素'
export const PORT_CAPTAIN_INSPECTION_WEIGHT = 30
export const PORT_CAPTAIN_INSPECTION_TEXT = '码头船长检查结果因素'

export const DEFAULT_AVATAR_URL = 'https://img.js.design/assets/img/649bcf911e724b59e713889b.png#fe1b83ff74fd1b1ee62ea5f700407891'

export function calcScoreWithWeight(score: number, _weight: number): string {
  return score ? Math.floor((score * INDUSTRY_SELF_REGULATION_WEIGHT / 100)).toFixed(0) : ''
}

export const TABLE_SORTABLE_OPTION = {
  sortDirections: ['ascend', 'descend'],
  sorter: true,
} satisfies TableSortable

export enum ROLE_NAME {
  超级管理员 = 'SUPER_ADMIN',
  财务账号 = 'FIN_ACCOUNT',
  主管账号 = 'MGR_ACCOUNT',
  码头管理员 = 'DOCK_ADMIN',
  码头账号 = 'DOCK_ACCOUNT',
  货主账号 = 'SHIPPER_ACCOUNT',
  专家账号 = 'EXPERT_ACCOUNT',
  海事管理员 = 'MARITIME_ADMIN',
  海事账号 = 'MARITIME_ACCOUNT',
  航运管理员 = 'SHIPPING_ADMIN',
  航运账号 = 'SHIPPING_ACCOUNT',
}

export const MT_PREMIUM_TIP = '请注意，该功能仅限于付费码头用户使用。如需享用此功能，建议您升级到我们的付费版服务，以获取更全面的商业功能和专业支持。'
export const HY_PREMIUM_TIP = '立即开通，享受更多功能。'
export const REVIEW_OPTIONS = [
  {
    label: '通过',
    value: 1,
  },
  {
    label: '驳回',
    value: 2,
  },
]

export const COLORS = [
  '#f53f3f',
  '#7816ff',
  '#00b42a',
  '#165dff',
  '#ff7d00',
  '#eb0aa4',
  '#7bc616',
  '#86909c',
  '#b71de8',
  '#0fc6c2',
  '#ffb400',
  '#168cff',
  '#ff5722',
  'red',
  'orangered',
  'orange',
  'gold',
  'lime',
  'green',
  'cyan',
  'blue',
  'arcoblue',
  'purple',
  'pinkpurple',
  'magenta',
  'gray',
]
export const PASSWORD_STRONGER_TIP = '已设置。密码至少12位字符，支持数字、字母和除空格外的特殊字符，且必须同时包含数字和大小写字母。'

export const REGULATION_CHECKLIST_TABLE_COLUMNS = [
  {
    title: '序号',
    dataIndex: 'num',
    slotName: 'num',
    width: 80,
  },
  {
    title: '内容及说明',
    dataIndex: 'matter',
    slotName: 'matter',
  },
  {
    title: '权重',
    dataIndex: 'weight',
    slotName: 'weight',
    width: 100,
  },
  {
    title: '扣分',
    dataIndex: 'deductMarks',
    slotName: 'deductMarks',
    width: 100,
  },
  {
    title: '附件',
    dataIndex: 'accessoryUrlArr',
    slotName: 'accessoryUrlArr',
    width: 100,
  },
  {
    title: '备注',
    dataIndex: 'remark',
    slotName: 'remark',
    width: 150,
  },
]
