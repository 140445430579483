import type { App } from 'vue'

import Breadcrumb from './breadcrumb/index.vue'
import Chart from './chart/index.vue'

// 动态导入 (代码分割)
async function loadEchartsModules() {
  const [{ use }, { CanvasRenderer }] = await Promise.all([
    import('echarts/core'),
    import('echarts/renderers'),
  ])

  const [
    { BarChart, LineChart, PieChart, RadarChart },
    {
      DataZoomComponent,
      GraphicComponent,
      GridComponent,
      LegendComponent,
      TooltipComponent,
    },
  ] = await Promise.all([
    import('echarts/charts'),
    import('echarts/components'),
  ])

  use([
    CanvasRenderer,
    BarChart,
    LineChart,
    PieChart,
    RadarChart,
    DataZoomComponent,
    GraphicComponent,
    GridComponent,
    LegendComponent,
    TooltipComponent,
  ])
}

export default {
  install(Vue: App) {
    loadEchartsModules()
    Vue.component('Chart', Chart)
    Vue.component('Breadcrumb', Breadcrumb)
  },
}
