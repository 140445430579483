export function DEFAULT_LAYOUT() {
  return import('~/layout/DefaultLayout.vue')
}
export function PAGE_LAYOUT() {
  return import('~/layout/PageLayout.vue')
}

export const WHITE_LIST = [
  { name: 'notFound', children: [] },
  { name: 'login', children: [] },
  { name: 'register', children: [] },
  { name: 'forget', children: [] },
]

export const NOT_FOUND = {
  name: 'notFound',
}

export const NO_LOGIN = [
  loginRouteName,
  registerRouteName,
  forgetRouteName,
  landingPageRouteName,
]
