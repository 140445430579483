import type { ROLE_NAME } from '~/composables/constant'

export type RoleType = '' | '*' | 'admin' | 'user'
export interface SystemUserVO {
  avatar: string
  createBy: string
  createTime: string
  companyId: string
  companyName: string
  delFlag: string
  deptId: string
  email: string
  giteeLogin: string
  lockFlag: string
  miniOpenid: string
  name: string
  nickname: string
  oscId: string
  password: string
  phone: string
  qqOpenid: null
  tenantId: string
  updateBy: string
  updateTime: string
  userId: string
  username: string
  wxOpenid: string
}
export class SystemUser implements SystemUser {
  avatar = ''
  createBy = ''
  createTime = ''
  companyId = ''
  companyName = ''
  delFlag = ''
  deptId = ''
  email = ''
  giteeLogin = ''
  lockFlag = ''
  miniOpenid = ''
  name = ''
  nickname = ''
  oscId = ''
  password = ''
  phone = ''
  qqOpenid = null
  tenantId = ''
  updateBy = ''
  updateTime = ''
  userId = ''
  username = ''
  wxOpenid = ''
}
export interface UserState {
  /**
   * 1是中海油类型的，2是鼠浪湖类型的 0是普通的
   */
  portCaptainInspectionSpecial: number
  permissions: string[]
  roles: string[]
  role: string
  roleCode: ROLE_NAME | null
  premiumCompany: boolean
  premiumExpiringSoon: boolean
  hasShip: boolean
  sysUser: SystemUserVO
}
