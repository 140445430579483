import type { UserState } from '~/store/modules/user/types'
import { requestWithAdmin } from './AxiosInstance/admin'

export interface FileRes {
  fileId: string
  url: string
}

export interface SaveUserInfo {
  userId: string
  avatar?: string
  password?: string
  newpassword1?: string
}

export function updateUserInfo(data: SaveUserInfo) {
  return requestWithAdmin.post('/admin/user/update', data)
}

export function putUserInfo(data: SaveUserInfo) {
  return requestWithAdmin.put('/admin/user/edit', data)
}

export interface BasicInfoModel {
  email: string
  nickname: string
  countryRegion: string
  area: string
  address: string
  profile: string
}

export function userUploadApi(
  data: FormData,
  config: {
    controller: AbortController
    onUploadProgress?: (progressEvent: any) => void
  },
) {
  // const controller = new AbortController();
  return requestWithAdmin.post<FileRes>('/admin/sys-file/upload', data, config)
}

export function getUserInfo() {
  return requestWithAdmin.get<UserState>('/admin/user/info')
}

export function queryDictTree(type = 0) {
  return requestWithAdmin.get(`/admin/dict/${type}/tree`)
}
