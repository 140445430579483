import type { UserState } from './types'
import { defineStore } from 'pinia'
import type { LoginData } from '~/api/user'
import {
  loginByMobile,
  login as userLogin,
  logout as userLogout,
} from '~/api/user'
import { getUserInfo } from '~/api/user-center'
import { clearAllUserInfo, setToken, setUserInfo } from '~/utils/auth'
import { encryption } from '~/utils/encrypt'
import { removeRouteListener } from '~/utils/route-listener'
import useAppStore from '../app'
import { SystemUser } from './types'

const useUserStore = defineStore('user', {
  state: (): UserState => ({
    portCaptainInspectionSpecial: 0,
    permissions: [],
    roles: [],
    role: '0',
    roleCode: ROLE_NAME.超级管理员,
    premiumCompany: false,
    premiumExpiringSoon: false,
    hasShip: false,
    sysUser: new SystemUser(),
  }),

  getters: {
    userInfo(state: UserState): SystemUser {
      return { ...state.sysUser }
    },
  },

  actions: {
    // switchRoles() {
    //   return new Promise((resolve) => {
    //     this.roles = this.role === 'user' ? 'admin' : 'user'
    //     resolve(this.role)
    //   })
    // },
    // Set user's information
    setInfo(partial: Partial<UserState>) {
      this.$patch(partial)
      setUserInfo(partial)
    },

    // Reset user's information
    resetInfo() {
      clearAllUserInfo()
      this.$reset()
    },

    // Get user's information
    async info() {
      const res = await getUserInfo()
      this.setInfo(res.data)
      setTimeout(() => {
        if (!globalComputedIsHyVip.value && res.data.hasShip) {
          globalFunctionHyDialogOpen()
        }
        else {
          if (res.data.premiumExpiringSoon)
            globalFunctionHyDialogOpen()
        }
      }, 2000)
    },

    // Login
    async login(loginForm: LoginData) {
      const encryptionLoginForm = encryption({
        data: loginForm,
        param: ['password'],
      })
      const res = await userLogin(encryptionLoginForm)
      if (res)
        setToken(res.access_token)
    },

    // Login
    async loginPhone(phone: string, code: string) {
      const res = await loginByMobile(phone, code)
      if (res) {
        setToken(res.access_token)
      }
    },

    logoutCallBack() {
      const appStore = useAppStore()
      this.resetInfo()
      removeRouteListener()
      appStore.clearServerMenu()
    },
    // Logout
    async logout() {
      try {
        userLogout()
      }
      finally {
        this.logoutCallBack()
      }
    },
  },
})

export default useUserStore
