import type { PaginationParams, R, R_Pagination } from '~/types/global'
import { requestWithVqc } from '../AxiosInstance/vqc'

// ShipLib
export interface ShipDict {
  id: string
  shipNameCh: string
  useCompanyId: string
}

export function queryShipList() {
  return requestWithVqc.request<Array<ShipDict>>({
    url: '/shipinfo/simple/list',
    method: 'get',
  })
}

export interface ShipLibParams extends Partial<ShipLibRecord>, PaginationParams {}
export interface ShipLibRecord {
  id: string
  deptId: string
  code: string
  shipCode: string
  shipOperatorShort: string
  securityOfficer: string
  securityPhone: string
  shipType: string
  score: string
  shipLong: string
  shipWide: string
  speed: string
  loadLine: string
  shipDepth: string
  scoreGrade: string
  shipRoute: string
  shipArea: string
  buildTime: string
  maritimeOrg: string
  registryPort: string
  shipCargoType: string
  shipowner: string
  shipownerEn: string
  shipOperator: string
  certificateTime: string
  certificateNum: string
  enginePower: string
  mmsi3: string
  colour: string
  iocf3: string
  callSign: string
  deadWeight: string
  netWeight: string
  totalWeight: number
  shipHigh: string
  nationality: string
  shipNameEn: string
  shipNameCh: string
  status: number
  helmNum: string
  industryStatus: number
  refuseReason: string
  engineNum: string
  shipSurvey: string
  shipAge: number
  companyId: string
  useCompanyId: string
  useCompanyName: string
}

export interface ShipLibQuery {
  useCompanyId: string
  shipNameCh: string
  shipCargoType: string
}

export function initShipLibQueryForm(): ShipLibQuery {
  return {
    useCompanyId: '',
    shipNameCh: '',
    shipCargoType: '',
  }
}

export function initShipLibForm(): ShipLibRecord {
  return {
    id: '',
    deptId: '',
    code: '',
    shipCode: '',
    shipOperatorShort: '',
    securityOfficer: '',
    securityPhone: '',
    shipType: '',
    score: '',
    shipLong: '',
    shipWide: '',
    speed: '',
    loadLine: '',
    shipDepth: '',
    scoreGrade: '',
    shipRoute: '',
    shipArea: '',
    buildTime: '',
    maritimeOrg: '',
    registryPort: '',
    shipCargoType: '',
    shipowner: '',
    shipownerEn: '',
    shipOperator: '',
    certificateTime: '',
    certificateNum: '',
    enginePower: '',
    mmsi3: '',
    colour: '',
    iocf3: '',
    callSign: '',
    deadWeight: '',
    netWeight: '',
    totalWeight: 0,
    shipHigh: '',
    nationality: '',
    shipNameEn: '',
    shipNameCh: '',
    status: 1,
    helmNum: '',
    industryStatus: 0,
    refuseReason: '',
    engineNum: '',
    shipSurvey: '',
    shipAge: 0,
    companyId: '',
    useCompanyId: '',
    useCompanyName: '',
  }
}

export function queryShipLibPage(params: ShipLibParams): R_Pagination<ShipLibRecord> {
  return requestWithVqc.request({
    url: '/shipinfo/page',
    method: 'get',
    params,
  })
}

export function queryShipLib(id: string) {
  return requestWithVqc.get(`/shipinfo/detail/${id}`)
}

export function querySearchShipList(name: string) {
  return requestWithVqc.request<Array<ShipLibRecord>>({
    url: '/shipinfo/search/list',
    method: 'get',
    params: {
      name,
    },
  })
}

export function deleteShipLib(id: string) {
  return requestWithVqc.delete(`/shipinfo/${id}`)
}

export function postShipLib(data: ShipLibRecord): Promise<R<boolean>> {
  return requestWithVqc.request({
    url: '/shipinfo',
    method: 'post',
    data,
  })
}

export function putShipLib(data: ShipLibRecord): Promise<R<boolean>> {
  return requestWithVqc.request({
    url: '/shipinfo',
    method: 'put',
    data,
  })
}

export function queryShipPremiumList() {
  return requestWithVqc.get('/shipinfo/premium/list')
}
