import type { AppRouteRecordRaw } from '../types'
import { DEFAULT_LAYOUT } from '~/router/constants'

const SURVEY_INSPECTION: AppRouteRecordRaw = {
  path: '/survey_inspection',
  name: 'SurveyInspection',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: '问卷管理',
    requiresAuth: true,
    icon: 'icon-check-square',
    order: 2,
  },
  children: [
    {
      path: 'ship',
      name: 'ShipSurvey',
      component: () => import('~/views/survey_inspection/ship/index.vue'),
      meta: {
        // 问卷管理/船舶自身状况
        locale: '船舶自身状况',
        requiresAuth: true,
        roles: [
          ROLE_NAME.超级管理员, // 0
          ROLE_NAME.海事管理员, // 3
          ROLE_NAME.海事账号, // 3
        ],
      },
    },

    {
      path: 'maritime_supervision',
      name: 'MaritimeSupervisionResult',
      component: () => import('~/views/survey_inspection/maritime_supervision/index.vue'),
      meta: {
        // 问卷管理/海事监管结果
        locale: '海事监管结果',
        requiresAuth: true,
        roles: [
          ROLE_NAME.超级管理员, // 0
          ROLE_NAME.海事管理员, // 3
          ROLE_NAME.海事账号, // 3
        ],
      },
    },
    {
      path: 'industry_self_regulation_inspection',
      name: 'IndustrySelfRegulationInspectionSurvey',
      component: () => import('~/views/survey_inspection/industry_self_regulation_inspection/index.vue'),
      meta: {
        // 问卷管理/行业自律检查
        locale: '行业自律检查',
        requiresAuth: true,
        roles: [
          ROLE_NAME.超级管理员, // 0
          ROLE_NAME.码头管理员, // 2
          ROLE_NAME.码头账号, // 2
          ROLE_NAME.海事管理员, // 3
          ROLE_NAME.海事账号, // 3
        ],
      },
    },
    {
      path: 'port_captain_inspection',
      name: 'PortCaptainInspectionSurvey',
      component: () => import('~/views/survey_inspection/port_captain_inspection/index.vue'),
      meta: {
        // 问卷管理/码头船长检查
        locale: '码头船长检查',
        requiresAuth: true,
        roles: [
          ROLE_NAME.超级管理员, // 0
          ROLE_NAME.码头管理员, // 2
          ROLE_NAME.码头账号, // 2
          ROLE_NAME.海事管理员, // 3
          ROLE_NAME.海事账号, // 3
        ],
      },
    },
  ],
}

export default SURVEY_INSPECTION
