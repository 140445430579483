import { AES, enc, mode, pad } from 'crypto-js'

interface Params<T> {
  data: T
  param: string[]
}
/**
 *加密处理
 */
export function encryption<T>(params: Params<T>) {
  const key = 'pigxpigxpigxpigx'
  const { data, param } = params
  const result = JSON.parse(JSON.stringify(data))
  param.forEach((ele) => {
    const data = result[ele]
    const iv = enc.Latin1.parse(key)
    // 加密
    const encrypted = AES.encrypt(data, iv, {
      iv,
      mode: mode.CFB,
      padding: pad.NoPadding,
    })
    result[ele] = encrypted.toString()
  })
  return result
}

// 加密函数
export function encryptPass(password: string, key = 'pigxpigxpigxpigx') {
  try {
    // 将密码和密钥转换为字节数组
    const passwordBytes = enc.Utf8.parse(password)
    const keyBytes = enc.Utf8.parse(key)

    // 使用 AES 加密算法进行加密
    const encrypted = AES.encrypt(passwordBytes, keyBytes, {
      mode: mode.ECB, // 使用 ECB 模式
      padding: pad.Pkcs7, // 使用 PKCS7 填充
    })

    // 返回加密后的密码
    return encrypted.toString()
  }
  catch (error) {
    console.error('Encryption error:', error)
    throw error // Re-throw the error after logging it
  }
}
// 解密函数
export function decryptPass(encryptedPassword: string, key = 'pigxpigxpigxpigx') {
  try {
    // 将密钥转换为字节数组
    const keyBytes = enc.Utf8.parse(key)

    // 使用 AES 解密算法进行解密
    const decrypted = AES.decrypt(encryptedPassword, keyBytes, {
      mode: mode.ECB, // 使用 ECB 模式
      padding: pad.Pkcs7, // 使用 PKCS7 填充
    })

    // 返回解密后的密码
    return decrypted.toString(enc.Utf8)
  }
  catch (error) {
    // 捕获异常并返回空字符串
    console.error('解密密码时出现异常:', error)
    return ''
  }
}
